import { useState, useEffect } from 'react'
import { Store } from '../../store/Store'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArticleLinkWrapper from './ArticleLinkWrapper';

export default function ArticleCategoriesComponent(props) {
    const [sortedCategories, setSortedCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(null)

    const categories = Store.getState().newsInfo.categories

    useEffect(() => {
        let sorted = categories.slice()
        const index = sorted.findIndex(category => {
            return category.name === "PDF"
        });
        if (index > -1) sorted.splice(index, 1);
        sorted.sort(function(categA, categB) {
            var nameA = categA.name.toUpperCase(); // ignore upper and lowercase
            var nameB = categB.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        })
        setSortedCategories(sorted)
    }, [categories])

    const articles = selectedCategory 
        ? Store.getState().newsInfo.articles.filter(article => article.category === selectedCategory.name).slice(0, 5) 
        : null

    return (<>
        <div className="categ-title">
            {selectedCategory && 
            <ArrowBackIcon 
                className="categ-title-back-button"
                onClick={() => setSelectedCategory(null)}
            />}
            <h3>{selectedCategory ? selectedCategory.name.toUpperCase() : "CATEGORIES"}</h3>
        </div>
        
        <div className="horiz-arrangement">
            <div className="vert-line"></div>
            {!selectedCategory && 
            <div>
                {sortedCategories.map(category => {
                    return (
                    <div 
                        key={category.id} 
                        className="rel-item" 
                        onClick={() => { setSelectedCategory(category) }}
                    >
                        <p className="categ">{category.name.toUpperCase()}</p>
                    </div>
                    )
                })}
            </div>}
            {selectedCategory &&
            <div>
                {articles?.map(article => {
                    const content = () => {
                        return (
                            <div className="rel-item" onClick={() => { setSelectedCategory(null) }}>
                                <p className="article-name" dangerouslySetInnerHTML={{__html: article.title.toUpperCase()}}/>
                                <p className="desc">{props.formatter.format(article.date).toUpperCase()}</p>
                            </div>
                        )
                    }
                    return (
                        <ArticleLinkWrapper
                            key={article.id}
                            article={article}
                            cellContent={content}
                            onNonPDFClick={props.onNonPDFClick}
                        />
                    )
                })}
            </div>
            }
        </div>
    </>)
}