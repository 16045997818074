import CloseIcon from '@material-ui/icons/Close';
import { useState } from 'react';
import { connect } from 'react-redux';
import '../../styles/ErrorPopupComponent.css';

function ErrorPopupComponent(props) {
    const [closed, setClosed] = useState(false)

    return (<>
    {
    !closed && props.loadingError &&
    <div id="loading-error" className={props.zoomedIn ? "error-zoomed-in" : "error-zoomed-out"}>
        <CloseIcon className="close-icon" onClick={() => setClosed(true)}/>
        <p className="error-text">Some locations failed to load. Check your connection and try refreshing the site for better experience.</p>
    </div>
    }
    </>)
}

const mapStateToProps = (state) => ({
    loadingError: state.loadingError,
    zoomedIn: state.zoomedIn
})

export default connect(mapStateToProps)(ErrorPopupComponent)