import '../../styles/NavigationPanel.css';
import * as Constants from '../../utils/Constants';
import * as ActionTypes from '../../store/ActionTypes';
import {Store} from '../../store/Store';
import {NavLink} from 'react-router-dom'

export default function NavigationPanel() {
  return (
    <>
      <div className="container-shadow">
        <div className="navigation-container">
          <div className="banner">
            <p>I3 has joined forces with Accelerator for America.{' '}
              <a
                href="https://www.acceleratorforamerica.org/thei3">
                Click here to visit the new i3 at AFA website
              </a>.
            </p>
          </div>
          <div id="navigation-panel">
            <NavLink exact
                     className="button"
                     activeClassName="selected"
                     to="/"
                     onClick={() => {
                       if (Store.getState().zoomedIn) {
                         Store.dispatch({
                           type: ActionTypes.ACTION_SET_ZOOMEDIN_AND_ANIMATING,
                           animating: true,
                           zoomedIn: false
                         })
                       }
                     }}>
              PROJECTS
            </NavLink>
            <NavLink className="button" activeClassName="selected" to={Constants.ABOUT_TAB}>
              ABOUT
            </NavLink>
            <NavLink className="button" activeClassName="selected" to={Constants.MEMBERS_TAB}>
              PARTNERS
            </NavLink>
            <NavLink className="button" activeClassName="selected" to={Constants.LETTER_TAB}>
              INITIATIVES
            </NavLink>
            <NavLink className="button" activeClassName="selected" to={Constants.NEWS_TAB}>
              NEWS & INSIGHTS
            </NavLink>
          </div>
          <NavLink className="logo" exact to="/">
            <img
              id="logo-img" src={Constants.LOGO_URL}
              alt="Logo"
              onClick={() => {
                if (Store.getState().zoomedIn) {
                  Store.dispatch({
                    type: ActionTypes.ACTION_SET_ZOOMEDIN_AND_ANIMATING,
                    animating: true,
                    zoomedIn: false
                  })
                }
              }}
            />
          </NavLink>
        </div>
      </div>
    </>
  )
}
