import React, {useEffect, useState, useRef} from 'react'
import '../../../styles/tabs/AboutTabComponent.css'
import * as DataFetch from '../../../api_comm/DataFetch'
import NewsLetterComponent from "../NewsLetterComponent";

const BLOCK_TYPE = {
  full: 'full_width',
  medium: 'medium_width'
}

export default function AboutTabComponent() {
  const [popup, setPopup] = useState({shown: false, content: undefined})
  const scrollRef = useRef(null)

  const [rows, setRows] = useState()

  useEffect(() => {
    DataFetch.fetchAbout(json => {
      setRows(json.rows.sort((a, b) => a._order - b._order))
    })

    let currentLocation = window.location.href
    const hasScrollIndicator = currentLocation.includes("/#")
    if (!hasScrollIndicator) return
    const scrollIndicator = `${currentLocation.substring(currentLocation.indexOf("#") + 1)}`
    if (scrollIndicator === 'bottom') {
      scrollRef.current?.scrollIntoView({behavior: 'smooth'})
    }
  }, [])

  const hoverModal = (e, content) => {
    const el = e.target.closest("u");
    if (el && e.currentTarget.contains(el)) {
      setPopup({shown: true, content: content})
    }
  }

  return (<div id="about-root">
    <div className="main-content">
      {
        rows &&
        rows.filter(r => r['block_type'] === BLOCK_TYPE.full).map(function (item, index) {
          return (<div className="about-row" key={item.id}>
            <p className="title" dangerouslySetInnerHTML={{__html: item.name}}/>
            <div className="text"
                 onPointerMove={item.modal_present ? (e) => hoverModal(e, item.modal_content) : undefined}
                 dangerouslySetInnerHTML={{__html: item.content}}
            />
          </div>)
        })}
      <div className="mid-content">
        {
          rows &&
          rows.filter(r => r['block_type'] === BLOCK_TYPE.medium).map(function (item, index) {
            return (<div className="mid-content-row" key={item.id}>
              <p className="title" dangerouslySetInnerHTML={{__html: item.name}}/>
              <div className="text"
                   onPointerMove={item.modal_present ? (e) => hoverModal(e, item.modal_content) : undefined}
                   dangerouslySetInnerHTML={{__html: item.content}}
              />
              {
                item.button &&
                <a className="rounded button" href={item.button_url}
                   dangerouslySetInnerHTML={{__html: item.button_name}}/>
              }
            </div>)
          })}
      </div>
    </div>
    <div id="popup-background" onClick={() => setPopup({shown: false, content: popup.content})}
         className={popup.shown ? "visible" : ""}>
      <div id="popup">
        <div dangerouslySetInnerHTML={{__html: popup.content}}/>
        <button
          id="popup-button"
          className="rounded button"
          onClick={() => setPopup({shown: false, content: popup.content})}
        >
          Got it
        </button>
      </div>
    </div>
  </div>)
}
