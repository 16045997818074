import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useThree, useFrame } from 'react-three-fiber';
import * as ActionTypes from '../../store/ActionTypes.js';
import PinInfo from '../../model/PinInfo.js';
import { CameraController } from './Three/CameraController'

const cameraCtrl = new CameraController()

function CameraMovementHandler(props) {
    const { 
        camera
    } = useThree();

    const cameraController = useRef(cameraCtrl)
    const propsRef = useRef(props)
    
    useEffect(() => {
        if(props.zoomedIn) {
            cameraController.current.moveToPin(props.pinSelected)
        } else if(camera.position.distanceTo(props.cameraInfo.cameraTop) > 0.001) {
            cameraController.current.moveToOrigin()
        }
    }, [props, camera]);

    useEffect(() => {
        cameraController.current.setOrigin(props.cameraInfo.cameraTop, props.cameraInfo.mapLookAt)
    }, [props.cameraInfo.cameraTop, props.cameraInfo.mapLookAt]);

    useEffect(() => {
        cameraController.current.init(camera,
                                      () => { propsRef.current.dispatch( { type: ActionTypes.ACTION_CAMERA_START_ANIMATING }) },
                                      () => { propsRef.current.dispatch( { type: ActionTypes.ACTION_CAMERA_FINISH_ANIMATING }) })
    }, [camera]);

    useFrame(() => { 
        cameraController.current.update()
    })

    return (<></>)
}

const mapStateToProps = (state) => ({
    cameraInfo: state.cameraInfo,
    pinSelected: state.pins[state.selectedPinIndex] ?? PinInfo.defValue(),
    zoomedIn: state.zoomedIn,
    pinIndex: state.selectedPinIndex
})

export default connect(mapStateToProps)(CameraMovementHandler)