import { useFrame } from 'react-three-fiber';
import * as TWEEN from "@tweenjs/tween.js";

export function TWEENUpdater(props) {

    useFrame(() => { 
        TWEEN.update() 
    })

    return <></>
}


