import Article from './Article'

export class Author {
    static fromJson(json) {
        return new Author(json.id, json.username)
    }

    constructor(id, name) {
        this.id = id
        this.name = name
    }
}

export class Category {
    static fromJson(json) {
        return new Category(json.id, json.name)
    }

    constructor(id, name) {
        this.id = id
        this.name = name
    }
}

export default class NewsInfo {
    static fromJson(json) {
        const authors = json.articles_authors.map(auth_json => Author.fromJson(auth_json))
        const categories = json.articles_categories.map(categ_json => Category.fromJson(categ_json))
        const pagesCount = json.articles.last_page
        const articles = json.articles.data.map(item => {
            const author = authors.find(auth => auth.id === item.author_id)
            const category = categories.find(categ => categ.id === item.category_id)
            return new Article(item.id, item.name, author?.name ?? "Unknown author", new Date(item.created_at), 
                    category?.name ?? "Unknown category", item.image['720x720'], item.image['original'], item.slug)
        })
        articles.sort(function(a, b) {
            return b.date - a.date
        })
        return new NewsInfo(articles, authors, categories, 1, pagesCount)
    }

    constructor(articles, authors, categories, pagesLoaded, pagesTotal) {
        this.articles = articles
        this.authors = authors
        this.categories = categories
        this.pagesLoaded = pagesLoaded
        this.pagesTotal = pagesTotal
    }

    addPage(json) {
        this.pagesLoaded += 1
        const newPage = NewsInfo.fromJson(json)
        this.articles.push.apply(this.articles, newPage.articles)
        this.pagesTotal = newPage.pagesTotal
    }
}