import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFrame } from 'react-three-fiber';
import { useThree } from 'react-three-fiber'

function PinModelAnimComponent(props) {
    const {
        scene,
    } = useThree()

    const mixer = useRef(null)    
    const lastPinSelected = useRef(null)

    useFrame((state, delta) => {
        mixer.current?.update(delta)
    })

    useEffect(() => {
        if(props.startAnimIn && props.pinSelected.loadedGLTF) {
            const moving = (lastPinSelected.current !== null && lastPinSelected.current !== props.pinSelected)
            lastPinSelected.current?.animateOut(moving, () => {})
            props.pinSelected?.animateIn(moving, () => {})
            mixer.current = props.pinSelected?.startModelAnimation()
        }
        lastPinSelected.current = props.pinSelected
    }, [props.startAnimIn, props.pinSelected, scene]);

    useEffect(() => {
        if(props.startAnimOut && lastPinSelected.current && lastPinSelected.current.loadedGLTF) {
            lastPinSelected.current.animateOut(false, () => {
                lastPinSelected.current = null
            })
        }
    }, [props.startAnimOut, scene]);

    return (<></>)
} 

const mapStateToProps = (state) => ({
    startAnimIn: state.zoomedIn,
    startAnimOut: !state.zoomedIn && state.animating,
    pinSelected: state.pins[state.selectedPinIndex] ?? undefined,
    pins: state.pins
})

export default connect(mapStateToProps)(PinModelAnimComponent)