import { createStore } from "redux"
import { actionHandlers } from "./ActionHandlers"
import CameraInfo from "../model/CameraInfo.js"
import produce from "immer"
import { LoadingInfo } from "../model/LoadingInfo"

const initialState = {
    zoomedIn: false,
    animating: false,
    pins: [],
    uiPositions: {},
    selectedPinIndex: 0,
    cameraInfo: CameraInfo.defaultValue(),
    loadingInfo: LoadingInfo.defaultValue(),
    newsInfo: null,
    loadingErrorOccured: false,
    projects: null
}

const reducer = (state = initialState, action) => produce(state, draft => {
    const actionHandler = actionHandlers[action.type]
    if (actionHandler) {
        actionHandler(state, draft, action)
    }
})


export const Store = createStore(reducer)