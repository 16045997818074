import * as ActionTypes from "./ActionTypes";

export const actionHandlers = {
    [ActionTypes.ACTION_SET_PINS](state, draft, action) {
        draft.pins = action.pins
    },

    [ActionTypes.ACTION_CAMERA_FINISH_ANIMATING](state, draft, action) {
        draft.animating = false
    },

    [ActionTypes.ACTION_CAMERA_START_ANIMATING](state, draft, action) {
        draft.animating = true
    },

    [ActionTypes.ACTION_SET_ZOOMEDIN](state, draft, action) {
        draft.zoomedIn = action.zoomedIn
    },

    [ActionTypes.ACTION_SET_PIN_INDEX](state, draft, action) {
        draft.selectedPinIndex = action.index
    },

    [ActionTypes.ACTION_SELECT_NEXT_PIN](state, draft, action) {
        draft.selectedPinIndex = state.selectedPinIndex >= state.pins.length - 1 ? 0 : state.selectedPinIndex + 1
    },

    [ActionTypes.ACTION_SELECT_PREVIOUS_PIN](state, draft, action) {
        draft.selectedPinIndex = state.selectedPinIndex > 0 ? state.selectedPinIndex - 1 : state.pins.length - 1
    },

    [ActionTypes.ACTION_SET_CAMERA_INFO](state, draft, action) {
        draft.cameraInfo = action.cameraInfo
    },

    [ActionTypes.ACTION_SET_UI_POSITIONS](state, draft, action) {
        draft.uiPositions = action.uiPositions
    },

    [ActionTypes.ACTION_SET_ZOOMEDIN_AND_ANIMATING](state, draft, action) {
        draft.zoomedIn = action.zoomedIn
        draft.animating = action.animating
    },

    [ActionTypes.ACTION_SET_LOADING_INFO](state, draft, action) {
        draft.loadingInfo = action.loadingInfo
    },

    [ActionTypes.ACTION_SET_NEWS_INFO](state, draft, action) {
        draft.newsInfo = action.newsInfo
    },

    [ActionTypes.ACTION_SET_LOADING_ERROR](state, draft, action) {
        draft.loadingError = action.loadingError
    },

    [ActionTypes.ACTION_SET_PROJECTS](state, draft, action) {
        draft.projects = action.projects
    }
    
}