import * as THREE from 'three';

export class ThreeHelper {
    static getWorldCoords(object) {
        var targetPosition = new THREE.Vector3()
        object.getWorldPosition(targetPosition)
        var targetScale = new THREE.Vector3()
        object.getWorldScale(targetScale)
        var targetRotation = new THREE.Quaternion()
        object.getWorldQuaternion(targetRotation)
        return {
            pos: targetPosition,
            rot: new THREE.Euler().setFromQuaternion(targetRotation),
            scale: targetScale
        }
    }
}