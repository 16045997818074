import * as Constants from '../utils/Constants.js'
import PinInfo from './PinInfo.js'
import CameraInfo from './CameraInfo.js'
import { ThreeHelper } from '../utils/ThreeHelper.js'
import { Model3D } from './Model3D.js'

export class Map extends Model3D {
    constructor() {
        super(Constants.MAP_MODEL_URL)
    }

    extractPinInfo() {
        const points = this.loadedGLTF.scene.children.find(el => el.name === Constants.MAP_POINTS_PARENT)
        return points.children.map((point, index) => PinInfo.fromObject(point, index))
    }

    extractCameraInfo() {
        const cameraSpot = this.loadedGLTF.scene.children.find(el => el.name === Constants.CAMERA_TOP_POSITION)
        return new CameraInfo(ThreeHelper.getWorldCoords(cameraSpot).pos, Constants.CAMERA_LOOK_AT_POS_TOP)
    }

}