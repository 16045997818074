import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { IconButton, makeStyles } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import '../../styles/PlaceInfoSideMenu.css';
import * as ActionTypes from '../../store/ActionTypes.js';
import PinInfo from '../../model/PinInfo.js';
import { withStyles } from "@material-ui/core/styles";
import * as HelpersUI from '../../utils/HelpersUI'
import * as DataFetch from '../../api_comm/DataFetch'

const useStyles = makeStyles({
    root: {
        "&:hover": {
            backgroundColor: "transparent",
            transition: {}
        }
    }
})

const MenuIconButton = withStyles({
    label: {
        display: "flex",
        flexDirection: "column"
    }
})(IconButton);

function PlaceInfoSideMenu(props) {
    const [expanded, setExpanded] = useState(true)
    const contentRef = useRef(null)
    const classes = useStyles();

    useEffect(() => {
        if (!props.projects) {
            DataFetch.fetchProjects(json => {
                var projects = {}
                json.forEach(element => {
                    projects[element.model_name] = element
                })
                props.dispatch( { type: ActionTypes.ACTION_SET_PROJECTS, projects: projects } )
            })
        }
    }, [props])

    //useEffect(() => {}, [props.projects])

    useEffect(() => {
        setExpanded(true)
        HelpersUI.scrollToTop(contentRef)
    }, [props.pinSelected, props.visible])

    function ExpandButton() {      
        return (
            <MenuIconButton disableRipple={true} className={classes.root} id="close-button" onClick={() => setExpanded(true)}>
                <div className="button-label">INFO</div>
                <ChevronLeftIcon fontSize="large" className="button-image"/>
            </MenuIconButton>
        );
    }
      
    function CollapseButton() {        
        return (
            <MenuIconButton disableRipple={true} className={classes.root} id="close-button" onClick={() => setExpanded(false)}>
                <div className="button-label">CLOSE</div>
                <ChevronRightIcon fontSize="large" className="button-image"/>
            </MenuIconButton>
        );
    }

    function backToMap() {
        props.dispatch({ type: ActionTypes.ACTION_SET_ZOOMEDIN_AND_ANIMATING, animating: true, zoomedIn: false })
    }

    return (
        <>
        {
        props.visible && props.projects &&
        <div id="side-menu" className={expanded ? "expanded" : "collapsed"}>
            <div className="button-panel">
                {
                    expanded 
                    ? CollapseButton()
                    : ExpandButton()
                }
            </div>
            <div className="content" ref={contentRef}>
                <div>
                    <h1 dangerouslySetInnerHTML={{__html: props.projects[props.pinSelected.name].name.toUpperCase()}}/>
                </div>
                <div>
                    <h2 dangerouslySetInnerHTML={{__html: props.projects[props.pinSelected.name].subtitle}}/>
                    <h3 dangerouslySetInnerHTML={{__html: props.projects[props.pinSelected.name].location}}/>
                </div>
                <hr></hr>
                <div>
                    <h4>STATUS</h4>
                    <div dangerouslySetInnerHTML={{__html: props.projects[props.pinSelected.name].status}}/>
                </div>
                <div>
                    <h4>INNOVATION</h4>
                    <div dangerouslySetInnerHTML={{__html: props.projects[props.pinSelected.name].innovation}}/>
                </div>
                <div>
                    <h4>PROJECT DESCRIPTION</h4>
                    <div dangerouslySetInnerHTML={{__html: props.projects[props.pinSelected.name].description}}/>
                </div>
                <div>
                    <h4>ENVIRONMENTAL & SOCIAL IMPACT</h4>
                    <div dangerouslySetInnerHTML={{__html: props.projects[props.pinSelected.name].impact}}/>
                </div>
                <div>
                    <h4>POLICY IMPLICATION</h4>
                    <div dangerouslySetInnerHTML={{__html: props.projects[props.pinSelected.name].implication}}/>
                </div>
                <div>
                    <h4>KEY STAKEHOLDERS</h4>
                    {
                        props.projects[props.pinSelected.name].stakeholders.map(function(item, index) {
                            if (item.link != null) {
                                return (
                                    <span key={item.title}><a href={item.link} className="text button" target="_blank" rel="noopener noreferrer">{item.title}</a><br/></span>
                                );
                            } else {
                                return (
                                    <span key={item.title}>{item.title}<br/></span>
                                );
                            }
                        })
                    }
                </div>
                <div className="buttons">
                    <a className="rounded button" href={props.projects[props.pinSelected.name].website} target="_blank" rel="noopener noreferrer">Website</a>
                    <button className="rounded button" onClick={() => backToMap()}>Back to Map</button>
                </div>
            </div>
        </div>
        }
        </>
    )
}

const mapStateToProps = (state) => ({
    visible: state.zoomedIn,
    pinSelected: state.pins[state.selectedPinIndex] ?? PinInfo.defValue(),
    projects: state.projects
})

export default connect(mapStateToProps)(PlaceInfoSideMenu)
