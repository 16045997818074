import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import '../../styles/PlaceInfoPopup.css';
import { connect } from 'react-redux';
import * as DataFetch from '../../api_comm/DataFetch'
import * as ActionTypes from '../../store/ActionTypes.js'


const FooterIconButton = withStyles({
    root: {
        padding: '0',
        margin: '0',
        color: 'black'
    },
})(IconButton);

function PlaceInfoPopup(props) {
    const [hovered, setHovered] = useState(false)

    useEffect(() => {
        if (!props.projects) {
            DataFetch.fetchProjects(json => {
                var projects = {}
                json.forEach(element => {
                    projects[element.model_name] = element
                })
                props.dispatch( { type: ActionTypes.ACTION_SET_PROJECTS, projects: projects } )
            })
        }
    }, [props])

    //useEffect(() => {}, [props.projects])

    useEffect(() => {
        setHovered(false)
    }, [props.visible])

    if(!props.pinSelected) return <></>
    const uiPosition = props.uiPositions[props.pinSelected.id]
    if(!uiPosition) return <></>
    var left = uiPosition.x + 'px';
    var right = window.innerWidth - uiPosition.x + 'px';
    var bottom = uiPosition.bottom + 'px'
    var transform = uiPosition.y < window.innerHeight / 2 ? 'translateY(calc(100% + 1vw))' : 'translateY(-1vw)'
    var padding = uiPosition.y < window.innerHeight / 2 ? 'calc(0.6vw + 25px) 1.3vw 1.2vw 1.3vw' : '1.2vw 1.3vw calc(0.6vw + 25px) 1.3vw'

    var clipPath;
    if (uiPosition.x < window.innerWidth / 2) {
        if (uiPosition.y < window.innerHeight / 2) {
            clipPath = 'polygon(0 0, 20px 20px, 100% 20px, 100% 100%, 0 100%)'
        } else {
            clipPath = 'polygon(0 0, 100% 0, 100% calc(100% - 20px), 20px calc(100% - 20px), 0 100%)';
        }
    } else {
        if (uiPosition.y < window.innerHeight / 2) {
            clipPath = 'polygon(0 20px, calc(100% - 20px) 20px, 100% 0, 100% 100%, 0 100%)'
        } else {
            clipPath = 'polygon(0 0, 100% 0, 100% 100%, calc(100% - 20px) calc(100% - 20px), 0 calc(100% - 20px))';
        }
    }

    const shown = props.visible && props.pinSelected.name !== ''

    return (
        <div id="place-popup-shadow" className={shown ? "popup-visible" : "popup-hidden"}>
        <div id="place-popup-container"
            style={
                uiPosition.x < window.innerWidth / 2
                ? {left, bottom, clipPath, transform, padding}
                : {right, bottom, clipPath, transform, padding}
            }
        >
            {props.projects &&
                <div id="place-popup"
                    onClick={() => { props.clicked(props.pinIndex) }}
                    onMouseEnter={() => { setHovered(true); props.setHover(true, props.pinIndex) }}
                    onMouseLeave={() => { setHovered(false); props.setHover(false, props.pinIndex) }}
                >
                    <div className="popup-title" dangerouslySetInnerHTML={{ __html: "<p>"+props.projects[props.pinSelected.name].name+"</p>" }}/>
                    <div className="popup-location" dangerouslySetInnerHTML={{ __html: "<p>"+props.projects[props.pinSelected.name].location+"</p>" }}/>
                    <div className="horizontal-line"></div>
                    <div className={hovered ? "popup-footer hovered" : "popup-footer"}>
                        Learn more
                <FooterIconButton>
                            <ChevronRightIcon className={hovered ? "popup-button hovered" : "popup-button"} />
                        </FooterIconButton>
                    </div>
                </div>}
        </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    projects: state.projects
})

export default connect(mapStateToProps)(PlaceInfoPopup)