import React from 'react';
import {Canvas} from 'react-three-fiber';
import MovementControls from '../ui/MovementControls';
import PlaceInfoPopup from '../ui/PlaceInfoPopup';
import PlaceInfoSideMenu from '../ui/PlaceInfoSideMenu';
import LoaderComponent from './LoaderComponent';
import {TWEENUpdater} from './TWEENUpdater.js';
import PinModelAnimComponent from './PinModelAnimComponent';
import PinsVisualComponent from '../ui/PinsVisualComponent';
import CurtainComponent from '../ui/CurtainComponent';
import CameraMovementHandler from './CameraMovementHandler';
import UIPositionHandler from './UIPositionHandler';
import {Provider} from "react-redux";
import {Store} from "../../store/Store";
import * as Constants from '../../utils/Constants.js';
import * as THREE from 'three';
import LoadingBarComponent from '../ui/LoadingBarComponent';
import ErrorPopupComponent from '../ui/ErrorPopupComponent';
import {ResizeObserver} from "@juggle/resize-observer"

export default function WorldComponent() {
  return (
    <>
      <Canvas
        // colorManagement={false}
        gl={{antialias: true}}
        camera={{
          position: [
            Constants.INIT_CAM_POS.x,
            Constants.INIT_CAM_POS.y,
            Constants.INIT_CAM_POS.z
          ],
          near: 0.01,
          far: 1000,
          fov: 33
        }}
        shadowMap
        onCreated={({gl}) => {
          gl.toneMapping = THREE.NoToneMapping
        }}
        style={{background: '#f6f8f9'}}
        resize={{polyfill: ResizeObserver}}
      >
        <spotLight
          intensity={0}
          position={[1, 1, 1]}
        />
        <Provider store={Store}>
          <TWEENUpdater/>
          <LoaderComponent/>
          <PinModelAnimComponent/>
          <CameraMovementHandler/>
          <UIPositionHandler/>
        </Provider>
      </Canvas>
      <Provider store={Store}>
        <CurtainComponent/>
        <MovementControls/>
        <PinsVisualComponent/>
        <PlaceInfoPopup/>
        <PlaceInfoSideMenu/>
        <LoadingBarComponent/>
        <ErrorPopupComponent/>
      </Provider>
    </>
  )
}
