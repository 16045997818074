import { IconButton, withStyles } from '@material-ui/core'
import { KeyboardArrowUp } from '@material-ui/icons'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useState, useEffect, useRef, useMemo } from 'react'
import * as DataFetch from '../../api_comm/DataFetch'
import Article from '../../model/Article';
import { Store } from '../../store/Store'
import '../../styles/ArticleComponent.css'
import ArticleCategoriesComponent from './ArticleCategoriesComponent';
import * as HelpersUI from '../../utils/HelpersUI'
import ArticleLinkWrapper from './ArticleLinkWrapper';

const ToTopIconButton = withStyles({
    root: {
        'margin': '2vw 0 5.5vw 0',
        'color': 'black',
        '&:hover': {
            'background-color': 'transparent',
            'color': 'var(--main-color)'
        },
        '&:active': {
            'color': 'var(--main-color-darker)'
        }
    },
    label: {
        display: 'flex',
        'flex-direction': 'column'
    },
})(IconButton);

export default function ArticleComponent(props) {
    const [article, setArticle] = useState(null)
    const rootRef = useRef(null)

    const nextArticle = useMemo(() => {
        if(!article) return null
        var articles = Store.getState().newsInfo.articles
        var index = articles.findIndex(a => a.id === article.id)
        return index + 1 >= articles.length ? articles[0] : articles[index + 1]
    }, [article])

    useEffect(() => {
        DataFetch.fetchArticleDetails(props.match.params.article_slug, (json) => {
            const loadedArticle = Article.fromJson(json)
            if(loadedArticle) setArticle(loadedArticle)
        })
    }, [props.match.params.article_slug])

    const formatter = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
        day: "2-digit"
    })

    const nextPostRow = () => {
        return (
            <div className="next-post-row">
                <div className="article-info">
                    <h3>NEXT POST</h3>
                    <h2 dangerouslySetInnerHTML={{__html: nextArticle.title}}/>
                    <p className="date">{formatter.format(nextArticle.date).toUpperCase()}</p>
                    <p className="author" dangerouslySetInnerHTML={{__html: nextArticle.author}}/>
                </div>
                <NavigateNextIcon className="circled-icon next-icon" fontSize="large"/>
            </div>
        )
    }

    function relatedArticles() {
        return article.related?.map(relatedArticle => {
            const content = () => {
                return <div className="rel-item">
                    <p className="title" dangerouslySetInnerHTML={{__html: relatedArticle.title.toUpperCase()}}/>
                    <p className="desc">{formatter.format(relatedArticle.date).toUpperCase()}</p>
                </div>
            }
            return (
                <ArticleLinkWrapper
                    key={relatedArticle.id}
                    article={relatedArticle}
                    cellContent={content}
                    onNonPDFClick={() => { HelpersUI.scrollToTop(rootRef) }}
                />
            )
        })
    }

    return <>
    { article &&
    <div id="article-root" ref={rootRef}>
        <img src={article.origImage} alt={article.title}/>
        <div className="article-content">
            <div className="title-row">
                <h1 dangerouslySetInnerHTML={{__html: article.title.toUpperCase()}}/>
                <div className="art-desc">
                    <p className="date">{formatter.format(article.date).toUpperCase()}</p>
                    <p className="author" dangerouslySetInnerHTML={{__html: article.author}}/>
                    <p className="categ">{article.category.toUpperCase()}</p>
                </div>
            </div>
            <div className="content-row">
                <div className="content-text" dangerouslySetInnerHTML={{__html: article.content}}/>
                <div className="related">
                    <ArticleCategoriesComponent formatter={formatter} onNonPDFClick={() => { HelpersUI.scrollToTop(rootRef) }}/>
                    {article.related && article.related.length > 0 && <h3>RELATED ARTICLES</h3>}
                    <div className="horiz-arrangement">
                        <div className="vert-line"></div>
                        <div>{ relatedArticles() }</div>
                    </div>
                </div>
            </div>
            <div>
                <ToTopIconButton 
                    disableRipple={true}
                    onClick={() => { HelpersUI.scrollToTop(rootRef) }} 
                >
                    <div className="up-text">Top</div>
                    <KeyboardArrowUp className="circled-icon up-icon"/>
                </ToTopIconButton>
            </div>
        </div>
        <ArticleLinkWrapper 
            article={nextArticle} 
            cellContent={nextPostRow} 
            onNonPDFClick={() => { HelpersUI.scrollToTop(rootRef) }}
        />
    </div>
    }
    </>
}