export default function PinPointComponent(props) {
    const left = props.left
    const top = props.top
    return (
        <div className="center" 
            style={{left, top}}
            onMouseEnter={() => props.setHover(true)}
            onMouseLeave={() => props.setHover(false)}
            onClick={() => { props.clicked() }}
        >
            <div className="circle pulse">
                <div className="circle-shadow"/>
            </div>
        </div>
    )
}