import '../../../styles/tabs/MembersTabComponent.css'
import { useState, useEffect } from 'react'
import * as DataFetch from '../../../api_comm/DataFetch'


export default function MembersTabComponent() {

    const [blocks, setBlocks] = useState()
    const [partners, setPartners] = useState()
    const [members, setMembers] = useState()

    useEffect(() => {
        DataFetch.fetchPartners(json => {
            setBlocks(json.blocks.sort((a, b) => a._order - b._order))
            setPartners(json.partners.sort((a, b) => a._order - b._order))
            setMembers(json.members.sort((a, b) => a._order - b._order))
        })
    }, [])

    return (<div id="members-root">
        <div id="blocks">
            {
            blocks &&
            blocks.map(function (item, index) {
                return (
                <div key={item.id}>
                     <div className="members-row">
                        <h1>{item.name}</h1>
                        <p id="coalition-text" dangerouslySetInnerHTML={{__html: item.content}}/>
                    </div>
                    <div className="separator"></div>
                </div>
                )
            })
            }
        </div>
        <div className="members-row">
            <h1>i3 PARTNERS</h1>
            <div id="partners">
                {
                    partners &&
                    partners.map(function(item, index) {
                        const itemStyle = !item.is_black ? {
                            WebkitFilter: 'grayscale(100%)',
                            filter: 'grayscale(100%)'
                        } : {}
                        return (
                            <a href={item.url} target="_blank" rel="noopener noreferrer" key={item.id}>
                                <img style={itemStyle} className="company-logo" src={item.image['300x0']} alt={item.id}/>
                            </a>
                        )
                    })
                }
            </div>
        </div>
        <div className="separator"></div>
        <div className="members-row">
            <div className="grid">
                <div className="cell" key="title">
                    <h1>ADVISORY BOARD</h1>
                </div>
                {
                    members &&
                    members.map(function (item, index) {
                        return (
                            <div className="cell" key={item.id}>
                                <div className="border">
                                    <img src={item.photo['480x480']} alt={item.id} />
                                </div>
                                <h2 dangerouslySetInnerHTML={{__html: item.firstname}}/>
                                <h2 dangerouslySetInnerHTML={{__html: item.surname}}/>
                                <h3 dangerouslySetInnerHTML={{__html: item.title}}/>
                                <h4 dangerouslySetInnerHTML={{__html: item.description}}/>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </div>)
}
