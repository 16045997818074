import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import '../../styles/CurtainComponent.css';
import { Store } from '../../store/Store'; 

const MOVE_FADE = "screen-change-move"
const ZOOM_IN_FADE = "screen-change-zoom-in"
const ZOOM_OUT_FADE = "screen-change-zoom-out"
const NO_FADE = ""

function CurtainComponent(props) {
    const [animate, setAnimate] = useState(NO_FADE)
    const lastPinIndex = useRef(props.pinIndex)
    
    useEffect(() => {
        var animTemp = NO_FADE
        if(props.zoomedIn && lastPinIndex.current !== props.pinIndex) {
            animTemp = MOVE_FADE
        } else if(props.zoomedIn && Store.getState().animating) {
            animTemp = ZOOM_IN_FADE
        } else if(!props.zoomedIn && Store.getState().animating) {
            animTemp = ZOOM_OUT_FADE
        }
        
        setAnimate(animTemp)
        lastPinIndex.current = props.pinIndex
    }, [props])

    function getId() {
        const id = '_' + Math.random().toString(36).substr(2, 9);
        return id;
    }

    return <>
        <div key={getId()} id="curtain" className={animate}></div>
    </>
}

const mapStateToProps = (state) => ({
    zoomedIn: state.zoomedIn,
    pinIndex: state.selectedPinIndex,
})

export default connect(mapStateToProps)(CurtainComponent)