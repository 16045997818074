import '../../styles/SimplePopup.css';
import React, {useState} from "react";

const SimplePopup = () => {
  const [popup, setPopup] = useState({shown: true})

  return (
    <div id="simple-popup">
      <div id="popup-background" onClick={() => setPopup({shown: false})}
           className={popup.shown ? "visible" : ""}>
        <div id="popup">
          <div>
            <div className="logos">
              <img src="/icons/logo_afa_i3_combined.png" alt="i3 AFA combined logo" />
            </div>

            <p>The Innovative Infrastructure Initiative has joined forces with Accelerator for America to transform infrastructure delivery in U.S. cities.</p>
            <p><a href="https://www.acceleratorforamerica.org/thei3" rel="noopener">Visit the new i3 at AFA website</a></p>
            <p><a href="https://www.acceleratorforamerica.org/news/the-innovative-infrastructure-initiative-joins-forces-with-accelerator-for-america-to-transform-infrastructure-delivery-in-us-cities" rel="noopener">Read the announcement</a></p>
          </div>
          <button
            id="popup-button"
            className="rounded button"
            onClick={() => setPopup({shown: false})}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  )
}
export default SimplePopup;
