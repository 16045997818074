import '../../styles/FooterPanel.css';
import * as Constants from '../../utils/Constants';
import {NavLink} from 'react-router-dom';
import React, {useState} from "react";

export default function FooterPanel() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer-container">
      <div id="footer-panel">
        <span className="leading">© {currentYear} Innovative Infrastructure Initiative</span>
        {/*<NavLink exact className="button" to={Constants.ABOUT_TAB + '#bottom'}>*/}
        {/*    Newsletter*/}
        {/*</NavLink>*/}
        <a className="button" href="mailto:i3@acceleratorforamerica.org">
          Connect
        </a>
        {/*<NavLink exact className="button" activeClassName="selected" to={Constants.TERMS_TAB}>*/}
        {/*  I<span className="superscript">3</span> Terms & Conditions of Use*/}
        {/*</NavLink>*/}
        {/*<NavLink exact className="button" activeClassName="selected" to={Constants.PRIVACY_TAB}>*/}
        {/*  Privacy Policy*/}
        {/*</NavLink>*/}
        {/*<NavLink exact className="button" activeClassName="selected" to={Constants.ACCESSIBILITY_TAB}>*/}
        {/*  Accessibility Statement*/}
        {/*</NavLink>*/}
      </div>
    </div>
  )
}
