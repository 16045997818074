import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"

export class Model3D {
    constructor(pathToModel) {
        this.path = pathToModel
        this.startedLoading = false
        this.loaded = false
        this.loadedGLTF = undefined
        this.loadCallback = (gltf) => {}
    }

    load(loadCallback, errorCallback = () => {}) {
        if(this.startedLoading || this.loaded) {
            console.log('Model3D - map already loading/loaded!')
            return
        }
        this.startedLoading = true
        this.loadCallback = loadCallback
        var mapLoader = new GLTFLoader()
        mapLoader.load(this.path,
            (gltf) => { this.onLoaded(gltf) },
            (progress) => { /* handle progress? */ },
            (error) => { 
                console.log('Model3D - FATAL error - model (' + this.path + ') was not loaded:\n' + error) 
                errorCallback()
            }
        )
    }

    onLoaded(gltf) {
        this.loadedGLTF = gltf
        this.loaded = true
        this.loadCallback(gltf)
    }
}