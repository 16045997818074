import React, { useState } from 'react'
import WorldComponent from './components/3d/WorldComponent';
import PasswordComponent from './components/ui/PasswordComponent';
import NavigationPanel from './components/ui/NavigationPanel';
import AboutTabComponent from './components/ui/tabs/AboutTabComponent';
import LetterTabComponent from './components/ui/tabs/LetterTabComponent';
import MembersTabComponent from './components/ui/tabs/MembersTabComponent';
import NewsTabComponent from './components/ui/tabs/NewsTabComponent';
import LegalsTabComponent from './components/ui/tabs/LegalsTabComponent';
import * as Constants from './utils/Constants.js'
import FooterPanel from './components/ui/FooterPanel';
import { Route, Switch } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import InApp from 'detect-inapp';
import './App.css';
import SimplePopup from "./components/ui/SimplePopup";

const mobileBlockedTabs = ["", Constants.ABOUT_TAB, Constants.MEMBERS_TAB, Constants.LETTER_TAB,
Constants.TERMS_TAB, Constants.PRIVACY_TAB, Constants.ACCESSIBILITY_TAB, Constants.NEWS_TAB,
Constants.NEWS_TAB + "/*"]
const inAppBlockedTabs = ["", Constants.ABOUT_TAB, Constants.MEMBERS_TAB, Constants.LETTER_TAB,
Constants.TERMS_TAB, Constants.PRIVACY_TAB, Constants.ACCESSIBILITY_TAB]

function App() {
  const [validUser, setValidUser] = useState(!Constants.PASSWORD_PROTECTED)
  const inapp = new InApp(navigator.userAgent)

  return (
    <>
      {!validUser &&
        <PasswordComponent setValidUser={setValidUser}/>
      }
      {validUser &&
      <div>
        <Route exact path={inapp.isInApp ? inAppBlockedTabs : mobileBlockedTabs}>
          {
            isMobile &&
            <div id="portrait-turn">
              <img id="logo" src={Constants.LOGO_URL} alt="sip"></img>
              <img id="image" src={Constants.PORTRAIT_TURN_URL} alt="portrait-turn-icon"></img>
              <p>Rotate your phone<br/>for optimal viewing</p>
            </div>
          }
        </Route>
        <div id="world-comp">
            <WorldComponent/>
            <Switch>
              <Route path={Constants.ABOUT_TAB}>
                  <AboutTabComponent/>
              </Route>
              <Route path={Constants.MEMBERS_TAB}>
                  <MembersTabComponent/>
              </Route>
              <Route path={Constants.LETTER_TAB}>
                  <LetterTabComponent/>
              </Route>
              <Route path={Constants.NEWS_TAB}>
                  <NewsTabComponent/>
              </Route>
              <Route path={Constants.TERMS_TAB}>
                  <LegalsTabComponent id={Constants.LEGALS_TERMS_ID}/>
              </Route>
              <Route path={Constants.PRIVACY_TAB}>
                  <LegalsTabComponent id={Constants.LEGALS_PRIVACY_ID}/>
              </Route>
              <Route path={Constants.ACCESSIBILITY_TAB}>
                  <LegalsTabComponent id={Constants.LEGALS_ACCESSIBILITY_ID}/>
              </Route>
            </Switch>
        </div>
        <NavigationPanel/>
        <SimplePopup/>
        <FooterPanel/>
      </div>}
    </>
  );
}

export default App;
