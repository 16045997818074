import * as THREE from 'three';
import { ThreeHelper } from '../utils/ThreeHelper.js'
import * as Constants from '../utils/Constants.js'
import { Model3D } from './Model3D.js'
import * as TWEEN from "@tweenjs/tween.js";

export default class PinInfo extends Model3D {
    static fromObject(pinObject, index) {
        var modelEnd = ThreeHelper.getWorldCoords(pinObject.children.find(el => el.name.includes(Constants.LOCATION_MODEL_END_POS)))
        var camSpot = ThreeHelper.getWorldCoords(pinObject.children.find(el => el.name.includes(Constants.LOCATION_CAMERA_POSITION)))
        var camIdleTarget = ThreeHelper.getWorldCoords(pinObject.children.find(el => el.name.includes(Constants.LOCATION_CAMERA_IDLE_TARGET_POSITION)))
        var locationPos = ThreeHelper.getWorldCoords(pinObject.children.find(el => el.name.includes(Constants.LOCATION_POSITION))).pos

        return new PinInfo(pinObject.id, index, pinObject.name, locationPos, modelEnd, camSpot, camIdleTarget)
    }

    static defValue() {
        return new PinInfo(
            0, 0, '',
            { pos: new THREE.Vector3(), rot: new THREE.Euler(), scale: new THREE.Vector3() },
            { pos: new THREE.Vector3(), rot: new THREE.Euler(), scale: new THREE.Vector3() },
            { pos: new THREE.Vector3(), rot: new THREE.Euler(), scale: new THREE.Vector3() },
            { pos: new THREE.Vector3(), rot: new THREE.Euler(), scale: new THREE.Vector3() }
        )
    }

    constructor(id, index, name, position, modelEnd, camSpot, camIdleTarget) {
        super(Constants.getPathToLocationModel(name))
        this.id = id
        this.index = index
        this.name = name
        this.position = position
        this.modelEnd = modelEnd
        this.camSpot = camSpot
        this.camIdleTarget = camIdleTarget
        this.currentTween = null
    }

    animateIn(moving, onFinished) {
        if(this.currentTween) {
            this.currentTween.stop()
            TWEEN.remove(this.currentTween)
        }

        const startScaleFactor = moving ? Constants.LOCATION_ANIMS.MOVE_IN.START_SCALE : Constants.LOCATION_ANIMS.ZOOM_IN.START_SCALE
        const startScaleValue = Constants.LOCATION_MODELS_SCALE * startScaleFactor
        var targetScale = new THREE.Vector3(Constants.LOCATION_MODELS_SCALE, Constants.LOCATION_MODELS_SCALE, Constants.LOCATION_MODELS_SCALE)

        const delay = moving ? Constants.LOCATION_ANIMS.MOVE_IN.DELAY : Constants.LOCATION_ANIMS.ZOOM_IN.DELAY
        const time = moving ? Constants.LOCATION_ANIMS.MOVE_IN.TIME : Constants.LOCATION_ANIMS.ZOOM_IN.TIME

        var tweenObject = this.loadedGLTF?.scene
        if(!tweenObject) return
        tweenObject.visible = true
        var scale = new THREE.Vector3(startScaleValue, startScaleValue, startScaleValue)
        var tween = new TWEEN.Tween(scale)
        .to(targetScale, time)
        .delay(delay)
        .onUpdate(function() {
            tweenObject.scale.set(scale.x, scale.y, scale.z)
        })
        .onComplete(function() {
            onFinished()
        })

        this.currentTween = tween
        tween.start()
    }

    animateOut(moving, onFinished) {
        if(this.currentTween) {
            this.currentTween.stop()
            TWEEN.remove(this.currentTween)
        }

        const delay = moving ? Constants.LOCATION_ANIMS.MOVE_OUT.DELAY : Constants.LOCATION_ANIMS.ZOOM_OUT.DELAY
        const time = moving ? Constants.LOCATION_ANIMS.MOVE_OUT.TIME : Constants.LOCATION_ANIMS.ZOOM_OUT.TIME

        const endScaleFactor = moving ? Constants.LOCATION_ANIMS.MOVE_OUT.END_SCALE : Constants.LOCATION_ANIMS.ZOOM_OUT.END_SCALE
        const endScaleValue = Constants.LOCATION_MODELS_SCALE * endScaleFactor
        var targetScale = new THREE.Vector3(endScaleValue, endScaleValue, endScaleValue)

        var tweenObject = this.loadedGLTF?.scene
        if(!tweenObject) return
        var tween = new TWEEN.Tween(tweenObject.scale)
        .to(targetScale, time)
        .delay(delay)
        .onComplete(function() {
            tweenObject.scale.set(0, 0, 0)
            tweenObject.visible = false
            onFinished()
        })

        this.currentTween = tween
        tween.start()
    }

    startModelAnimation() {
        if (this.loadedGLTF.animations.length > 0) {
            var mixer = new THREE.AnimationMixer(this.loadedGLTF.scene)
            const action = mixer.clipAction(this.loadedGLTF.animations[0])
            action.play()
            return mixer
        }
        return null
    }

}