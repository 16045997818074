import * as Constants from '../utils/Constants'

export function fetchArticles(pageNum, onFinished) {
    fetch(Constants.getUrlToArticles(pageNum))
        .then(res => res.json())
        .then(
            (json) => { onFinished(json) },
            (error) => { console.log("ERROR - failed to load articles for NEWS tab!\n" + error) }
        )
}

export function fetchArticleDetails(slug, onFinished) {
    fetch(Constants.getUrlToArticleDetail(slug))
        .then(res => res.json())
        .then(
            (json) => { 
                onFinished(json.article) 
            },
            (error) => { console.log("ERROR - failed to load article " + this.slug + "!\n" + error) }
        )
}

export function fetchPartners(onFinished) {
    fetch(Constants.getUrlToPartners())
        .then(res => res.json())
        .then(
            (json) => { 
                onFinished(json) 
            },
            (error) => { console.log("ERROR - failed to load partners!\n" + error) }
        )
}

export function fetchProjects(onFinished) {
    fetch(Constants.getUrlToProjects())
        .then(res => res.json())
        .then(
            (json) => { 
                onFinished(json.projects) 
            },
            (error) => { console.log("ERROR - failed to load projects!\n" + error) }
        )
}

export function fetchInitiatives(onFinished) {
    fetch(Constants.getUrlToInitiatives())
        .then(res => res.json())
        .then(
            (json) => { 
                onFinished(json) 
            },
            (error) => { console.log("ERROR - failed to load initiatives!\n" + error) }
        )
}

export function fetchAbout(onFinished) {
    fetch(Constants.getUrlToAbout())
        .then(res => res.json())
        .then(
            (json) => { 
                onFinished(json) 
            },
            (error) => { console.log("ERROR - failed to load about!\n" + error) }
        )
}

export function fetchLegals(onFinished) {
    fetch(Constants.getUrlToLegals())
        .then(res => res.json())
        .then(
            (json) => {
                onFinished(json)
            },
            (error) => { console.log("ERROR - failed to load legals!\n" + error) }
        )
}