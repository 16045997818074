import { Store } from '../store/Store'

export default class Article {
    static loadRelated(json) {
        if(!json) return null
        return json.map(item => Article.fromJson(item))
    }

    static fromJson(json) {
        const authors = Store.getState().newsInfo.authors
        const categories = Store.getState().newsInfo.categories
        const author = authors.find(auth => auth.id === json.author_id)
        const category = categories.find(categ => categ.id === json.category_id)
        return new Article(json.id, json.name, author?.name ?? "Unknown author", new Date(json.created_at), 
                category?.name ?? "Unknown category", json.image['720x720'], json.image['original'], json.slug, 
                json.content, Article.loadRelated(json.related_articles))
    }

    constructor(id, title, author, date, category, image, origImage, slug, content = null, related = null) {
        this.id = id
        this.title = title
        this.author = author
        this.date = date
        this.category = category
        this.image = image
        this.origImage = origImage
        this.slug = slug
        this.content = content
        this.related = related
    }
}