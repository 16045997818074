import { useState, useEffect } from 'react'
import '../../../styles/tabs/LetterTabComponent.css'
//import FeatureForm from '../FeatureForm.js';
import * as DataFetch from '../../../api_comm/DataFetch'

export default function LetterTabComponent() {
    //const [featureForm, showFeatureForm] = useState(false)

    const [initiatives, setInitiatives] = useState()


    useEffect(() => {
        DataFetch.fetchInitiatives(json => {
            setInitiatives(json.initiatives.sort((a, b) => a._order - b._order))
        })
    }, [])

    return (<div id="letter-root">
        <div className="main-content">
            {
                initiatives &&
                initiatives.map(function (item, index) {
                    return ( <div key={item.id}>
                        <div className="letter-row">
                            <p className="row-title" dangerouslySetInnerHTML={{ __html: item.title }}/>
                            <div className="content">
                                <div dangerouslySetInnerHTML={{ __html: item.content }}/>
                                {item.button && <div>
                                    <a className="rounded button"
                                        href={item.button_link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        dangerouslySetInnerHTML={{ __html: item.button_name }}/>
                                </div>}
                            </div>
                        </div>
                        <div className="separator" />
                    </div>)
                })
            }
        {/*<div id="popup-background" className={featureForm ? "visible" : ""}>
            <FeatureForm id="feature-form" closeAction={ () => showFeatureForm(false) }/>
        </div>*/}
        </div>
    </div>)
}
