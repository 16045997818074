import * as THREE from 'three';
import * as TWEEN from "@tweenjs/tween.js";

//version settings
export const PASSWORD_PROTECTED = process.env.REACT_APP_PASSWORD_PROTECTED ?? false
export const PASSWORD = process.env.REACT_APP_PASSWORD ?? ""
export const SUBFOLDER_DEPLOYMENT_PATH = "/"

//init 3D settings
export const INIT_CAM_POS = new THREE.Vector3(34.71726131, 703.6611, 260)
export const INIT_CAM_LOOK_AT = new THREE.Vector3(0, -150, 0)
export const LOCATION_MODELS_SCALE = 20

//resources
export const X_URL = process.env.PUBLIC_URL + '/icons/X-The-Moonshot-Factory-logo.png'
export const ECFT_URL = process.env.PUBLIC_URL + '/icons/eno-logo.png'
export const GIH_URL = process.env.PUBLIC_URL + '/icons/GIH_Logo.jpg'
export const LOGO_URL = process.env.PUBLIC_URL + '/icons/I3_Logo_Final.svg'
export const ASCE_URL = process.env.PUBLIC_URL + '/icons/1200px-ASCE_logo.png'
export const CPIP_URL = process.env.PUBLIC_URL + '/icons/CPIP Logo.png'
export const SIP_URL = process.env.PUBLIC_URL + '/icons/SIP_PrimaryLogo_WhiteBackground.png'
export const AFA_URL = process.env.PUBLIC_URL + '/icons/afa.png'
export const BRICKMORTAR_URL = process.env.PUBLIC_URL + '/icons/logo-brick-and-motar-ventures.png'

export const BROWNER_URL = process.env.PUBLIC_URL + '/icons/headshots/browner.png'
export const BENJAMIN_URL = process.env.PUBLIC_URL + '/icons/headshots/benjamin.png'
export const AGGARWALA_URL = process.env.PUBLIC_URL + '/icons/headshots/aggarwala.jpg'
export const TOMER_URL = process.env.PUBLIC_URL + '/icons/headshots/tomer.jpg'
export const GRAY_URL = process.env.PUBLIC_URL + '/icons/headshots/gray.jpeg'
export const PORCARI_URL = process.env.PUBLIC_URL + '/icons/headshots/porcari.png'
export const BECHTEL_URL = process.env.PUBLIC_URL + '/icons/headshots/bechtel.png'
export const THOMAS_URL = process.env.PUBLIC_URL + '/icons/headshots/thomas.png'
export const GEDDES_URL = process.env.PUBLIC_URL + '/icons/headshots/geddes.png'
export const BARLOW_URL = process.env.PUBLIC_URL + '/icons/headshots/barlow.png'
export const HUNTER_URL = process.env.PUBLIC_URL + '/icons/headshots/hunter.png'
export const PUENTES_URL = process.env.PUBLIC_URL + '/icons/headshots/puentes.jpg'

export const PORTRAIT_TURN_URL = process.env.PUBLIC_URL + '/icons/portrait-turn.png'
export const USER_PLACEHOLDER_URL = process.env.PUBLIC_URL + '/icons/placeholder-photo.png'
export function getPathToLocationModel(locationName) {
    return './models3d/' + locationName + '/' + locationName + '.gltf'
}

export const MEMBERS = {
    "director": USER_PLACEHOLDER_URL,
    "benjamin": BENJAMIN_URL,
    "tomer": TOMER_URL,
    "gray": GRAY_URL,
    "browner": BROWNER_URL,
    "aggarwala": AGGARWALA_URL,
    "porcari": PORCARI_URL,
    "bechtel": BECHTEL_URL,
    "thomas": THOMAS_URL,
    "geddes": GEDDES_URL,
    "barlow": BARLOW_URL,
    "hunter": HUNTER_URL,
    "puentes": PUENTES_URL
}

export const PARTNERS = {
    "sip": SIP_URL,
    "cpip": CPIP_URL,
    "asce": ASCE_URL,
    "afa": AFA_URL,
    "gih": GIH_URL,
    "ecft": ECFT_URL,
    "x": X_URL,
    "brickmortar": BRICKMORTAR_URL,
}

export const MAILCHIMP_URL = "https://ithree.us1.list-manage.com/subscribe/post-json?u=3757e8a0698e863991f512d28&amp;id=ce172a307f&"

//fetching
export const API_HOST = process.env.REACT_APP_API
export const ARTICLES_PAGE_SIZE = 20
export const LEGALS_TERMS_ID = 1
export const LEGALS_PRIVACY_ID = 2
export const LEGALS_ACCESSIBILITY_ID = 4
export function getUrlToArticles(pageNum) {
    return API_HOST + "/api/articles?limit=" + ARTICLES_PAGE_SIZE + "&page=" + pageNum
}
export function getUrlToArticleDetail(slug) {
    return API_HOST + "/api/articles/" + slug
}
export function getUrlToPartners() {
    return API_HOST + "/api/partners"
}
export function getUrlToProjects() {
    return API_HOST + "/api/projects"
}
export function getUrlToInitiatives() {
    return API_HOST + "/api/initiatives"
}
export function getUrlToAbout() {
    return API_HOST + "/api/about"
}
export function getUrlToLegals() {
    return API_HOST + "/api/pages"
}

//navigation
export const MAP_TAB = "/map"
export const ABOUT_TAB = "/about"
export const MEMBERS_TAB = "/partners"
export const LETTER_TAB = "/initiatives"
export const NEWS_TAB = "/news-and-insights"
export const TERMS_TAB = "/terms"
export const PRIVACY_TAB = "/privacy"
export const ACCESSIBILITY_TAB = "/accessibility"

//loading models
export const MAP_MODEL_URL = './models3d/Map.gltf'
export const DEFAULT_MODEL_NAME = 'basalt'

//names of nodes in 3D model
export const LOCATION_MODEL_END_POS = 'modelEnd'
export const LOCATION_CAMERA_POSITION = 'pointCam'
export const LOCATION_CAMERA_IDLE_TARGET_POSITION = 'pointCamIdle'
export const LOCATION_POSITION = 'point'
export const MAP_POINTS_PARENT = 'Points'
export const CAMERA_TOP_POSITION = 'cameraTop'

//location model animating
export const LOCATION_ANIMS = {
    ZOOM_IN: {
        START_SCALE: 0.5,
        TIME: 1500,
        DELAY: 2200
    },
    MOVE_IN: {
        START_SCALE: 0.5,
        TIME: 1500,
        DELAY: 100
    },
    MOVE_OUT: {
        END_SCALE: 0.0,
        TIME: 100,
        DELAY: 100
    },
    ZOOM_OUT: {
        END_SCALE: 0.5,
        TIME: 500,
        DELAY: 0
    }
}

//camera
export const CAMERA_ANIMS = {
    ZOOM_IN: {
        SPEED: 2300,
        DELAY: 0,
        POS_EASING: TWEEN.Easing.Linear.None,
        LOOK_EASING: TWEEN.Easing.Linear.None,
    },
    MOVE: {
        SPEED: 100,
        DELAY: 100,
        POS_EASING: TWEEN.Easing.Linear.None,
        LOOK_EASING: TWEEN.Easing.Quartic.Out,
    },
    ZOOM_OUT: {
        SPEED: 2000,
        DELAY: 400,
        POS_EASING: TWEEN.Easing.Quartic.Out,
        LOOK_EASING: TWEEN.Easing.Quartic.Out,
    }
}

export const CAMERA_DOWN_LEVITATION_SPEED = 0.005
export const CAMERA_DOWN_LEVITATION_DISTANCE_FACTOR = 0.5 //has to be value from 0-1
export const CAMERA_LOOK_AT_POS_TOP = { x: 0, y: -150, z: 0 }

export const CAMERA_ZOOM_INTERPOLATION_USE_EXTRA_POINT = false