import '../../styles/tabs/NewsTabComponent.css'
import { CELL_CONSTS } from './tabs/NewsTabComponent'

export default function NewsCell(props) {
    const { type, cellSize, article } = props

    const size = type === CELL_CONSTS.FEATURED 
                ? cellSize * 2 + CELL_CONSTS.SPACING - 1 //fix for safari bad alignment (-1)
                : cellSize

    const formatter = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit"
    })

    const titleClass = type === CELL_CONSTS.FEATURED ? "featured-cell-title" : "cell-title"

    return (
        <div className="cell" style={{width: size, height: size}}>
            <img src={article.image} alt={article.title} style={{width: size}}/>
            <div className="foreground"/>
            <div className={type}>
                <div className={titleClass} dangerouslySetInnerHTML={{__html: article.title.toUpperCase()}}/>
                <div className="cell-subtitle">
                    {article.category.toUpperCase() + '\n' + 
                    formatter.format(article.date).toUpperCase()}
                </div>
            </div>
        </div>
    )
}