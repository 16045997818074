import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useThree } from 'react-three-fiber';
import * as ActionTypes from '../../store/ActionTypes.js'

function UIPositionHandler(props) {
    const { 
        camera,
        size
    } = useThree();

    useEffect(() => {
        if(props.zoomedIn || props.animating) return

        function getUIPosition(pos) {
            var vector = pos.clone().project(camera);
            vector.x = (vector.x + 1) / 2 * size.width;
            vector.y = -(vector.y - 1) / 2 * size.height;
            var bottom = size.height - vector.y
            return { x: vector.x, y: vector.y, bottom: bottom };
        }

        var positionsDictionary = {}
        props.pins.forEach(pin => {
            positionsDictionary[pin.id] = getUIPosition(pin.position)
        });

        props.dispatch({ type: ActionTypes.ACTION_SET_UI_POSITIONS, uiPositions: positionsDictionary })
    }, [camera, size, props]);

    return <></>
}

const mapStateToProps = (state) => ({
    pins: state.pins,
    zoomedIn: state.zoomedIn,
    animating: state.animating
})

export default connect(mapStateToProps)(UIPositionHandler)