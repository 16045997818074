import React, { useRef } from 'react'
import '../../styles/PasswordComponent.css';
import * as Constants from '../../utils/Constants'

export default function PasswordComponent(props) {
    const valid = useRef(false)

    const onPasswordChange = (event) => {
        valid.current = (event.target.value === Constants.PASSWORD)
    };

    return (
        <div id="background">
            <div id="password-popup">
                <div className="p-title">This content is protected. Enter password to proceed.</div>
                <input type="password" className="p-input" onChange={onPasswordChange}/>
                <button 
                    className="p-button" 
                    style={{ 'fontFamily': 'Sen-Regular' }}
                    onClick={() => {
                        if(valid.current) { props.setValidUser(true) }
                    }}
                >
                    Enter
                </button>
            </div>
        </div>
        
    )
}