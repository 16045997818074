import { useState, useEffect } from 'react'
import * as DataFetch from '../../api_comm/DataFetch'
import { Link } from 'react-router-dom'
import * as Constants from '../../utils/Constants'
import Article from '../../model/Article'

export default function ArticleLinkWrapper(props) {
    const { article, cellContent, onNonPDFClick } = props
    const isPdf = article.category === "PDF"

    const [url, setUrl] = useState(undefined)

    useEffect(() => {
        function stripHtml(html) {
            let tmp = document.createElement("DIV");
            tmp.innerHTML = html;
            return tmp.textContent || tmp.innerText || "";
        }

        if(article.category === "PDF") {
            DataFetch.fetchArticleDetails(article.slug, (json) => {
                const loadedArticle = Article.fromJson(json)
                let url = stripHtml(loadedArticle.content)
                if(!url.includes('https://') && !url.includes('http://')) {
                    url = 'https://' + url
                }
                setUrl(url)
            })
        }
    }, [article])

    return <>
        {
            isPdf &&
            <a href={url} target="_blank" rel='noreferrer'>
                { cellContent() }
            </a>
        }
        {
            !isPdf &&
            <Link key={article.id} to={Constants.NEWS_TAB + "/" + article.slug} onClick={onNonPDFClick}>
                { cellContent() }
            </Link>
        }
    </>
}