import React, { useRef, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import * as ActionTypes from '../../store/ActionTypes.js'
import '../../styles/PinsVisualComponent.css'
import PinPointComponent from './PinPointComponent.js'
import PlaceInfoPopup from './PlaceInfoPopup.js'
import { isMobile } from 'react-device-detect';

const DELAY_AFTER_LOSE_HOVER = 200
const DELAY_AFTER_CHANGED_EXTERNALLY = 2000

function PinsVisualComponent(props) {
    const [showPopup, setShowPopup] = useState(false)
    const hover = useRef(false)
    const timeoutFunction = useRef(null)

    function setPopupCloseWithTimer(time) {
        timeoutFunction.current = setTimeout(function() {
            if(!hover.current) setShowPopup(false)
        }, time)
    }

    //reaction on changes from outside
    useEffect(() => {
        function onIndexChangedOrBecameVisible() {
            setShowPopup(true)
            if(timeoutFunction.current) clearTimeout(timeoutFunction.current)
            if(!hover.current) {
                setPopupCloseWithTimer(DELAY_AFTER_CHANGED_EXTERNALLY)
            }
        }

        function onBecameHidden() {
            hover.current = false
        }

        if(!props.visible) {
            onBecameHidden()
        } else {
            onIndexChangedOrBecameVisible()
        }
    }, [props.pinIndex, props.visible])

    function hoverChanged(value, index) {
        if(isMobile) return
        if(timeoutFunction.current) clearTimeout(timeoutFunction.current)
        if(value) {
            hover.current = true
            setShowPopup(true)
            if(props.pinIndex !== index) {
                props.dispatch( { type: ActionTypes.ACTION_SET_PIN_INDEX, index: index } )
            }
        } else {
            hover.current = false
            setPopupCloseWithTimer(DELAY_AFTER_LOSE_HOVER)
        }
    }

    function clicked(index) {
        if(props.pinIndex === index) {
            props.dispatch({ type: ActionTypes.ACTION_SET_ZOOMEDIN_AND_ANIMATING, animating: true, zoomedIn: true })
        } else {
            setShowPopup(true)
            props.dispatch( { type: ActionTypes.ACTION_SET_PIN_INDEX, index: index } )
        }
    }

    return (
        <>
        {props.visible && props.pins.map((pin, index) => {
            const uiPosition = props.uiPositions[pin.id]
            if(!uiPosition) return (<div key={pin.id}></div>)
            var left = uiPosition.x - 10 + 'px';
            var top = uiPosition.y - 10 + 'px';
            return (
                <PinPointComponent
                    key={pin.id}
                    left={left}
                    top={top}
                    setHover={(value) => { hoverChanged(value, index) }}
                    clicked={() => { clicked(index) }}
                />
            )
        })}
        <PlaceInfoPopup
            visible={props.visible && showPopup}
            pinIndex={props.pinIndex}
            pinSelected={props.pinSelected}
            uiPositions={props.uiPositions}
            setHover={(value, index) => { hoverChanged(value, index) }}
            clicked={(index) => { clicked(index) }}
        />
        </>
    )
}

const mapStateToProps = (state) => ({
    visible: !state.zoomedIn && !state.animating,
    pins: state.pins,
    pinIndex: state.selectedPinIndex,
    pinSelected: state.pins[state.selectedPinIndex] ?? undefined,
    uiPositions: state.uiPositions
})

export default connect(mapStateToProps)(PinsVisualComponent)