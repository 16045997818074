import * as Constants from '../utils/Constants.js'

export default class CameraInfo {
    static defaultValue() {
        return new CameraInfo(
            Constants.INIT_CAM_POS,
            Constants.INIT_CAM_LOOK_AT
        )
    }

    constructor(cameraTop, mapLookAt) {
        this.cameraTop = cameraTop
        this.mapLookAt = mapLookAt
    }
}