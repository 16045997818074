import '../../../styles/tabs/LegalsTabComponent.css'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { IconButton, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react'
import { withStyles } from "@material-ui/core/styles";
import * as HelpersUI from '../../../utils/HelpersUI'
import * as DataFetch from '../../../api_comm/DataFetch'

const TopIconButton = withStyles({
    label: {
        display: "flex",
        flexDirection: "column"
    }
})(IconButton);

const useStyles = makeStyles({
    root: {
        "&:hover": {
            backgroundColor: "transparent",
            transition: {}
        }
    }
})

export default function LegalsTabComponent(props) {
    const { id } = props
    const scrollRef = useRef(null)
    const contentRef = useRef(null)
    const classes = useStyles()

    const [accCount, setAccCount] = useState(0)
    const [content, setContent] = useState(null)
    const [expanded, setExpanded] = useState(allExpanded())

    useEffect(() => {
        DataFetch.fetchLegals((json) => {
            let item = json.pages.find(el => el.id === id)
            setContent(item.content)
            var doc = new DOMParser().parseFromString(item.content, "text/html");
            var count = doc.getElementsByClassName("accordeon")?.length ?? 0
            setAccCount(count)
        })
    }, [id])

    function recountAllExpanded() {
        setExpanded(allExpanded())
    }

    const toggleAll = () => {
        let parents = contentRef.current.getElementsByClassName("accordeon")
        for (let index = 0; index < parents.length; index++) {
            let element = parents.item(index)
            element.classList.remove(expanded ? 'expanded' : 'collapsed')
            element.classList.add(expanded ? 'collapsed' : 'expanded')
        }
        recountAllExpanded()
    }

    function allExpanded() {
        if(!contentRef.current) return
        let row = contentRef.current?.getElementsByClassName("accordeon")
        for (let index = 0; index < row.length; index++) {
            let element = row.item(index)
            if(element.classList.contains('collapsed')) {
                return false
            }
        }
        return true
    }

    const contentClicked = (event) => {
        let parentDiv = event.target.closest('.accordeon')
        if(!parentDiv) return
        parentDiv.classList.toggle('collapsed')
        parentDiv.classList.toggle('expanded')
        recountAllExpanded()
    }

    return (<div id="privacy-root" ref={scrollRef}>
        <div className="sections-wrapper">
        <div ref={contentRef} onClick={contentClicked} dangerouslySetInnerHTML={{__html: content}}/>
        {
            accCount > 0 && 
            <button id="expand-all" onClick={ () => toggleAll() }>
                { expanded ? "CLOSE ALL" : "EXPAND ALL" }
            </button>
        }
        </div>
        <TopIconButton
            className={classes.root}
            disableRipple={true}
            id="top-button" 
            onClick={() => HelpersUI.scrollToTop(scrollRef) }
        >
            <div className="button-label">TOP</div>
            <KeyboardArrowUpIcon className="button-icon" fontSize="large"/>
        </TopIconButton>
    </div>)
}