import * as THREE from 'three';
import * as TWEEN from "@tweenjs/tween.js";
import * as Constants from '../../../utils/Constants.js'

export class CameraController {

    constructor() {
        this.movementTweens = []
        this.initialized = false
        this.currentLookAt = new THREE.Vector3(0,0,0)
        this.idleBouncePoint0 = new THREE.Vector3(0,0,0)
        this.idleBouncePoint1 = new THREE.Vector3(0,0,0)
        this.idleBounceTarget = null
        this.levitationValue = 0
    }

    init(camera, onMovementStarted, onMovementFinished) {
        this.camera = camera
        this.onMovementStarted = onMovementStarted
        this.onMovementFinished = onMovementFinished
        this.initialized = true
    }

    setOrigin(pos, lookAt) {
        this.originPos = pos
        this.originLookAt = lookAt
        this.moveToOrigin();
    }

    moveToPin(pinInfo) {
        this.moveTo(pinInfo.camSpot.pos, 
            pinInfo.modelEnd.pos, 
            pinInfo.camIdleTarget.pos, 
            this.hasBounceTarget() ?  Constants.CAMERA_ANIMS.MOVE.SPEED : Constants.CAMERA_ANIMS.ZOOM_IN.SPEED, 
            this.hasBounceTarget() ?  Constants.CAMERA_ANIMS.MOVE.DELAY : Constants.CAMERA_ANIMS.ZOOM_IN.DELAY,
            this.hasBounceTarget() ?  Constants.CAMERA_ANIMS.MOVE.POS_EASING : Constants.CAMERA_ANIMS.ZOOM_IN.POS_EASING, 
            this.hasBounceTarget() ?  Constants.CAMERA_ANIMS.MOVE.LOOK_EASING : Constants.CAMERA_ANIMS.ZOOM_IN.LOOK_EASING,)
    }

    moveToOrigin() {
        this.moveTo(this.originPos,
                    this.originLookAt,
                    null, 
                    Constants.CAMERA_ANIMS.ZOOM_OUT.SPEED,
                    Constants.CAMERA_ANIMS.ZOOM_OUT.DELAY,
                    Constants.CAMERA_ANIMS.ZOOM_OUT.POS_EASING,
                    Constants.CAMERA_ANIMS.ZOOM_OUT.LOOK_EASING,
                    )
    }

    moveTo(pos, lookAt, idleBouncePoint, duration, delay = 0, posEasing, rotEasing) {
        if(!this.initialized) { return }

        this.idleBounceTarget = null
        if(idleBouncePoint) {
            this.idleBouncePoint1.set(idleBouncePoint.x, idleBouncePoint.y, idleBouncePoint.z)
            this.idleBounceTarget = this.idleBouncePoint1
        }

        this.stopAllTweens();
        this.onMovementStarted()

        var points = []
        points.push(new THREE.Vector3().copy(this.camera.position))
        if(Constants.CAMERA_ZOOM_INTERPOLATION_USE_EXTRA_POINT) {
            // points.push(new THREE.Vector3(camPos.x + v1.x*0.5, camPos.y, camPos.z + v1.z*0.5))
            points.push(new THREE.Vector3(this.camera.position.x + pos.x, (this.camera.position.y + pos.y) * 0,5, this.camera.position.z + pos.z))
        }
        points.push(pos)
        var curve = new THREE.CatmullRomCurve3(points, false, 'catmullrom')

        // curve types:
        // 'catmullrom'
        // 'centripetal'
        // 'chordal'

        var posTween = new TWEEN.Tween({ x:0 })
        .to( { x:1 }, duration)
        .delay(delay)
        .easing(posEasing)
        .onUpdate(function(obj, progress) {
            var p = curve.getPoint(obj.x)
            this.camera.position.set(p.x, p.y, p.z)
        }.bind(this))
        .onComplete(function() {
            this.removeTween(posTween)
            this.idleBouncePoint0.set(this.camera.position.x, this.camera.position.y, this.camera.position.z)
            this.levitationValue = 0
            this.removeTween(posTween)
            this.onMovementFinished()
        }.bind(this))
        posTween.start()

        var tweenLookAt = new TWEEN.Tween(this.currentLookAt)
        .to(lookAt, duration)
        .delay(delay)
        .easing(rotEasing)
        .onUpdate(function(obj, progress) {
            this.camera.lookAt(this.currentLookAt)
        }.bind(this))
        .onComplete(function() {
            this.removeTween(tweenLookAt)
        }.bind(this))
        tweenLookAt.start()

        this.movementTweens.push(posTween)
        this.movementTweens.push(tweenLookAt)
    }

    update() {
        this.updateIdleBounce()
    }

    updateIdleBounce() {
        if(this.hasBounceTarget() && this.movementTweens.length === 0) {
            this.levitationValue += Constants.CAMERA_DOWN_LEVITATION_SPEED
            var change = Math.sin(this.levitationValue) * Constants.CAMERA_DOWN_LEVITATION_DISTANCE_FACTOR
            this.camera.position.lerpVectors(this.idleBouncePoint0, this.idleBouncePoint1, change)
            this.camera.lookAt(this.currentLookAt)
        }
    }

    hasBounceTarget() {
        return (typeof this.idleBounceTarget !== "undefined" && this.idleBounceTarget !== null)
    }

    duration(explicit) {
        return (typeof explicit !== 'undefined') ? explicit : 1000
    }

    stopAllTweens() {
        this.movementTweens.forEach(tween => { tween.stop() })
        this.movementTweens = []
    }

    removeTween(tween) {
        for (var i in this.movementTweens) {
            if (this.movementTweens[i] === tween) {
                this.movementTweens.splice(i, 1)
                break
            }
        }
    }

}
