import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import '../../styles/MovementControls.css';
import * as ActionTypes from '../../store/ActionTypes.js';
//import FeatureForm from './FeatureForm.js';

const ControlsIconButton = withStyles({
    root: {
        margin: '0.625vw 0.417vw 0 0.417vw',
        padding: '0',
        color: 'black',
        "&:hover": {
            backgroundColor: "transparent",
            transition: {}
        }
    }
})(IconButton);

function MovementControls(props) {
    //const [featureForm, showFeatureForm] = useState(false)

    function zoomTapped() {
        props.dispatch({ type: ActionTypes.ACTION_SET_ZOOMEDIN_AND_ANIMATING, animating: true, zoomedIn: !props.zoomedIn })
    }

    function previousPin() {
        props.dispatch({ type: ActionTypes.ACTION_SELECT_PREVIOUS_PIN })
    }

    function nextPin() {
        props.dispatch({ type: ActionTypes.ACTION_SELECT_NEXT_PIN })
    }

    return (
        <div id="movement-panel">
            <div id="movement-controls">
                <div className="controls-button-parent">
                    ZOOM
                    <ControlsIconButton disableRipple={true} onClick={() => zoomTapped()}>
                        {props.zoomedIn ? <RemoveIcon className="button-icon"/> : <AddIcon className="button-icon"/>}
                    </ControlsIconButton>
                </div> 
                
                <div className="vertical-line"></div>

                <div className="controls-button-parent">
                    PREVIOUS
                    <ControlsIconButton disableRipple={true} onClick={() => previousPin()}>
                        <ChevronLeftIcon className="button-icon"/>
                    </ControlsIconButton>
                </div>

                <div id="pin-index">{props.pinIndex + 1} / {props.pinsCount}</div>

                <div className="controls-button-parent">
                    NEXT
                    <ControlsIconButton disableRipple={true} onClick={() => nextPin()}>
                        <ChevronRightIcon className="button-icon"/>
                    </ControlsIconButton>
                </div>
            </div>
            <div className="horizontal-line"></div>
            <a className="button feature" href="mailto:partnerships@infrainitiative.com">Feature your project on the map</a>
            {/*<div id="popup-background" className={featureForm ? "visible" : ""}>
                <FeatureForm id="feature-form" closeAction={ () => showFeatureForm(false) }/>
            </div>*/}
        </div>
        
    )
}

const mapStateToProps = (state) => ({
    zoomedIn: state.zoomedIn,
    pinsCount: state.pins.length,
    pinIndex: state.selectedPinIndex,
})

export default connect(mapStateToProps)(MovementControls)
