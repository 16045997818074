export class PinModelsLoader {
    constructor() {
        this.startedLoading = false
        this.loadingFinished = false
        this.modelsFinished = 0
        this.modelsTotal = 0
        this.onModelFinished = (name, gltf, loadedCount) => {}
        this.onModelLoadError = () => {}
        this.onLoadingFinished = () => {}
    }

    startLoadingModels(pinsInfo, scene) {
        if(this.startedLoading) {
            console.log('PinModelsLoader - location models already loading!')
            return
        }
        this.startedLoading = true
        this.modelsFinished = 0
        this.modelsTotal = pinsInfo.length
        pinsInfo.forEach(pinInfo => {
            pinInfo.load(
                (gltf) => { 
                    var pinObj = gltf.scene
                    scene.add(pinObj)
                    pinObj.position.set(pinInfo.modelEnd.pos.x, pinInfo.modelEnd.pos.y, pinInfo.modelEnd.pos.z)
                    pinObj.scale.set(0, 0, 0)
                    this.modelLoaded() 
                },
                (error) => { this.modelLoadError(pinInfo.name) }
            )
        });
    }

    modelLoaded() {
        this.modelsFinished += 1
        this.onModelFinished(this.modelsFinished)
        if(this.modelsFinished === this.modelsTotal) {
            this.loadingFinished = true
            this.onLoadingFinished()
        }
    }

    modelLoadError(name) {
        console.log('Loading failed for model: ' + name)
        this.modelLoaded()
        this.onModelLoadError()
    } 

    getLocation(locationName) {
        return this.pinModels[locationName]
    }

}