import React, { useRef, useEffect } from 'react';
import * as ActionTypes from '../../store/ActionTypes.js';
import { Map } from '../../model/Map.js';
import { useThree } from 'react-three-fiber';
import { Store } from '../../store/Store.js';
import { PinModelsLoader } from './Three/PinModelsLoader.js';
import { LoadingInfo } from '../../model/LoadingInfo.js';

export default function LoaderComponent() {
    const {
        scene,
    } = useThree();

    const map = useRef(new Map());
    const pinModelLoader = useRef(new PinModelsLoader());

    useEffect(() => {
        function onMapLoaded(gltf) {
            scene.add(gltf.scene);
            var pinsInfo = map.current.extractPinInfo();
            Store.dispatch({ type: ActionTypes.ACTION_SET_CAMERA_INFO, cameraInfo: map.current.extractCameraInfo() })
            Store.dispatch({
                type: ActionTypes.ACTION_SET_LOADING_INFO,
                loadingInfo: new LoadingInfo(1, pinsInfo.length + 2)
            });
            pinModelLoader.current.onModelFinished = (loadedCount) => {
                Store.dispatch({
                    type: ActionTypes.ACTION_SET_LOADING_INFO,
                    loadingInfo: new LoadingInfo(loadedCount + 1, pinsInfo.length + 2)
                });
            };
            pinModelLoader.current.onModelLoadError = () => {
                Store.dispatch({ type: ActionTypes.ACTION_SET_LOADING_ERROR, loadingError: true })
            }
            pinModelLoader.current.onLoadingFinished = () => {
                Store.dispatch({ type: ActionTypes.ACTION_SET_PINS, pins: pinsInfo });
                Store.dispatch({
                    type: ActionTypes.ACTION_SET_LOADING_INFO,
                    loadingInfo: new LoadingInfo(pinsInfo.length + 2, pinsInfo.length + 2)
                })
            };
            pinModelLoader.current.startLoadingModels(pinsInfo, scene);
        }

        map.current.load(onMapLoaded);
    }, [scene]);

    return (<></>)
}
