export const ACTION_SET_PINS = "setPins"
export const ACTION_CAMERA_FINISH_ANIMATING = "cameraFinishAnimating"
export const ACTION_CAMERA_START_ANIMATING = "cameraStartAnimating"
export const ACTION_SET_ZOOMEDIN = "zoomedIn"
export const ACTION_SET_ZOOMEDIN_AND_ANIMATING = "setZoomedInAndAnimating"
export const ACTION_SET_PIN_INDEX = "setPinIndex"
export const ACTION_SELECT_NEXT_PIN = "selectNextPin"
export const ACTION_SELECT_PREVIOUS_PIN = "selectPreviousPin"
export const ACTION_SET_CAMERA_INFO = "setCameraInfo"
export const ACTION_SET_UI_POSITIONS = "setUiPositions"
export const ACTION_SET_LOADING_INFO = "setLoadingInfo"
export const ACTION_SET_NEWS_INFO = "setNewsInfo"
export const ACTION_SET_LOADING_ERROR = "setLoadingError"
export const ACTION_SET_PROJECTS = "setProjects"