export function scrollToTop(ref) {
    if(!ref.current || ref.current?.scrollTop === 0) return

    const cosParameter = ref.current?.scrollTop / 2;
    let scrollCount = 0, oldTimestamp = null;

    function step (newTimestamp) {
        if (oldTimestamp !== null) {
            scrollCount += Math.PI * (newTimestamp - oldTimestamp) / 400;
            if (scrollCount >= Math.PI) {
                ref.current.scrollTop = 0;
                return
            }
            ref.current.scrollTop = cosParameter + cosParameter * Math.cos(scrollCount);
        }
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
    }
    window.requestAnimationFrame(step);
}