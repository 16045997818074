import { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { CircularProgress } from '@material-ui/core'
import '../../../styles/tabs/NewsTabComponent.css'
import ArticleComponent from '../ArticleComponent'
import * as DataFetch from '../../../api_comm/DataFetch'
import { Store } from '../../../store/Store'
import * as ActionTypes from '../../../store/ActionTypes'
import NewsInfo from '../../../model/NewsInfo'
import { Route, Switch } from 'react-router-dom'
import * as Constants from '../../../utils/Constants'
import NewsCell from '../NewsCell'
import ArticleLinkWrapper from '../ArticleLinkWrapper'

export const CELL_CONSTS = {
    NORMAL: 'small-cell',
    FEATURED: 'featured-cell',
    SPACING: 8,
    CELLS_IN_ROW: 4,
}

export default function NewsTabComponent() {
    const [articles, setArticles] = useState(Store.getState().newsInfo?.articles)
    const [canLoadMore, setCanLoadMore] = useState(true)
    const [loadingMore, setLoadingMore] = useState(false)

    const gridListRef = useRef(null)
    const [gridWidth, setGridWidth] = useState(0);

    const cellHeight = (gridWidth - CELL_CONSTS.SPACING * CELL_CONSTS.CELLS_IN_ROW) / CELL_CONSTS.CELLS_IN_ROW

    useEffect(() => {
        if(Store.getState().newsInfo) { //articles already loaded
            var newsInfo = Store.getState().newsInfo
            setArticles(newsInfo.articles)
            if(newsInfo.pagesLoaded >= newsInfo.pagesTotal) {
                setCanLoadMore(false)
            }
            return
        } 
        DataFetch.fetchArticles(1, (json) => { //need to fetch
            const newsInfo = NewsInfo.fromJson(json)
            Store.dispatch({ type: ActionTypes.ACTION_SET_NEWS_INFO, newsInfo: newsInfo });
            setArticles(newsInfo.articles)
            if(newsInfo.pagesLoaded >= newsInfo.pagesTotal) {
                setCanLoadMore(false)
            }
        })
    }, [])

    useLayoutEffect(() => {
        function updateSize() {
            if(gridListRef.current) setGridWidth(gridListRef.current.clientWidth) 
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    });

    function loadMore() {
        var newsInfo = Store.getState().newsInfo
        setLoadingMore(true)
        DataFetch.fetchArticles(newsInfo.pagesLoaded + 1, (json) => {
            newsInfo.addPage(json)
            Store.dispatch({ type: ActionTypes.ACTION_SET_NEWS_INFO, newsInfo: newsInfo });
            setArticles(newsInfo.articles)
            if(newsInfo.pagesLoaded >= newsInfo.pagesTotal) {
                setCanLoadMore(false)
            }
            setLoadingMore(false)
        })
    }

    const cellContent = (article, index) => {
        return <NewsCell 
            key={article.id}
            type={index === 0 ? CELL_CONSTS.FEATURED : CELL_CONSTS.NORMAL} 
            cellSize={cellHeight} 
            article={article}
        />
    } 

    return <div id="news-root">
        {
            articles && 
            <>
            <div className="grid-anim">
                <div className="grid-list" ref={gridListRef}>
                    <div>
                        {articles.map((article, index) => {
                            return (
                                <ArticleLinkWrapper
                                    key={article.id}
                                    article={article}
                                    cellContent={() => cellContent(article, index)}
                                />
                            )
                        })}
                    </div>
                    {canLoadMore && <div className="load-more" style={{minWidth: gridWidth}}>
                        {!loadingMore && 
                        <button className="rounded button horiz-mid" onClick={() => loadMore()}>Load more</button>}
                        {loadingMore && 
                        <CircularProgress className="horiz-mid" style={{color: "var(--main-color)"}}/>}
                    </div>}
                </div>
            </div>
            <Switch>
                <Route path={Constants.NEWS_TAB + "/:article_slug"} component={ArticleComponent}>
                </Route>
            </Switch>
            </>
        }
        {
            !articles && 
            <div className={articles ? "loading-panel loading-fade-out" : "loading-panel"}>
                <CircularProgress style={{color: "var(--main-color)"}}/>
                <div className="loading-text">Loading articles</div>
            </div>
        }
    </div>
}