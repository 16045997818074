import { connect } from 'react-redux'
import { LinearProgress, withStyles } from '@material-ui/core';
import '../../styles/LoadingBarComponent.css'

const ShadowLinearProgress = withStyles({
    root: {
        'opacity': '0.7',
        'height': '1.5vw',
        'background-color': 'transparent',
        'margin': '0.4vw',
        'border-radius': '1.5vw'
    },
    bar: {
        'background-color': 'var(--main-color)',
        'height': '1.5vw',
        'border-radius': '1.5vw',
        'margin': 'auto 0'
    }
})(LinearProgress);

function LoadingBarComponent(props) {
    const finished = (props.loadingInfo.loaded === props.loadingInfo.total)
    const progress = props.loadingInfo.loaded / (props.loadingInfo.total) * 100

    return (<>
        {<div className={finished ? "loading-fade-out" : ""}>
            <div id="blocking-view"/>
            <div id="loading-parent">
                <div className="horiz-text">
                    <div className="left-text-1">
                        INNOVATIVE<br/>
                        PROJECT<br/>
                        SPOTLIGHT
                    </div>
                    <div className="right-text-1">
                        This interactive map features transformative innovative infrastructure projects across the U.S.<br/>
                        <span className="note">(These projects are not formally associated with i3.)</span>
                    </div>
                </div>
                <div id="loading-title">LOADING...</div>
                <div className="bar-main">
                    <ShadowLinearProgress
                        variant="determinate"
                        value={progress}
                    />
                </div>
            </div>
        </div>}
    </>)
}

const mapStateToProps = (state) => ({
    loadingInfo: state.loadingInfo
})

export default connect(mapStateToProps)(LoadingBarComponent)
